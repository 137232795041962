/*

let parallaxSimple = require("./parallaxSimple");

parallaxSimple({
  el: ".js-parallax-background",
  shiftMin: 0,
  shiftMax: 210,
  offset: 200
});

Настройки:
el - элемент с паралаксом
shiftMin, shiftMax - начальное и конечное значение свойства top
ofset - сдвиг (уменьшение) области действия, фактически, это высота элемента

Принцип работы:
принимает название элемента
высчитывает зону действия - размер вьюпорта экрана минус сдвиг
начинает действовать, когда элемент попадает в эту зону (вьюпорт)
скорость изменений зависит от высоты экрана

*/

module.exports = function (option) {
  // проверка
  if (option.el && document.querySelector(option.el) ) {
    init(option);
  }

  function init(option) {

    var windowHeight = window.innerHeight; // высота окна с прокруткой

    document.addEventListener('resize', function() {
      windowHeight = window.innerHeight;
    })

    var el = option.el;
    var speedFactor = option.speed;
    var shiftMin = option.shiftMin;
    var shiftMax = option.shiftMax;
    var offset = option.offset;
    var listOfParallaxEl = document.querySelectorAll(el);
    var getHeight;
    var firstTop;
    var paddingTop = 0;

    //get the starting position of each element to have parallax applied to it
    listOfParallaxEl.forEach(function(subEl){
        firstTop = subEl.getBoundingClientRect().top;
    });

    function getCoords(elem) {
      let box = elem.getBoundingClientRect();

      return {
        top: box.top + pageYOffset,
        left: box.left + pageXOffset
      };
    }

    // function to be called whenever the window is scrolled or resized
    function update(){

      var posTop = window.scrollY;
      var posBottom = posTop + windowHeight;

      listOfParallaxEl.forEach(function(subEl){
        var element = subEl;
        var elTopVP = element.getBoundingClientRect().top;
        var elHeight = element.clientHeight;
        var elCoordsTop = getCoords(element).top;
        var elCoordsBottom = elCoordsTop + elHeight;
        var viewZoneBottom = elCoordsTop;
        var viewZoneTop = viewZoneBottom - windowHeight;

        // элемент в зоне видимости
        if(elTopVP > 0 && elTopVP < windowHeight - offset) {

          var deltaPage = windowHeight - offset;
          var deltaElTop = shiftMax - shiftMin;
          var shiftPagePersent = elTopVP / deltaPage;
          var shiftEl = deltaElTop * shiftPagePersent + shiftMin;
          element.style.top = shiftEl + "px";
        }

      });
    }
    document.addEventListener('scroll', update)
    document.addEventListener('resize', update)
    update()

  }
}


