"use strict";

// ПОДКЛЮЧЕНИЕ

const $ = require("./jquery");
const jQuery = $;
window.jQuery = $;

require("./slick/slick.js");

// автоформы
const autoforms = require("./autoforms");

// maskedInput
const maskedInput = require("./maskedinput");

// отправка форм по аякс
const forms = require("./forms");

// цены
const accounting = require("./accounting");

let simpleTab = require("./simpleTab");

let popupItemsCut = require("./popupItemsCut");

let autoScrollToTag = require("./autoScrollToTag");

// пересчитываемые цифры
let mathAppear = require("./mathAppear");

// галерея
const initPhotoSwipe = require("./photoswipe/initPhotoSwipe");

let parallaxSimple = require("./parallaxSimple");


// НАСТРОЙКИ

// плавный скрол к якорям
autoScrollToTag({
    itemTag: ".js-scroll",
    duration: "50",
    speed: "1000",
    ofset: 0,
    parentTag: ".js-scroll-parent",
    noParentOfsetLessWhen: 1150
});

// Форматирование чисел в цене
$(".js-price").each(function( index ) {
  let str = $(this).text();
  str = accounting.formatNumber(str);
  $(this).text(str);
});

// Обрабатываем телефон
let telListNode = document.querySelectorAll(".js-tel");
var telList = Array.prototype.slice.call(telListNode, 0);
telList.forEach(function(telInp) {
  maskedInput(telInp).mask("+7 (999) 999-99-99");
});

// Автоформы
let autoformListNode = document.querySelectorAll(".js-autoform");
var autoformList = Array.prototype.slice.call(autoformListNode, 0);

autoformList.forEach(function(form)  {
  // console.log(form);
  autoforms.init(form, {
      Validators: {
        "maskphone": {
          "keys": "1234567890",
          "errorMessage": "Type only numbers",
          "validatorFunction": function validatorFunction(field) {
            return field.valid = field.nodeLink.value.indexOf("_") < 0;
          },
          "keypressValidatorFunction": false
        }
      }
  });
});

// отправка форм по аякс
forms(".js-form");

// Слайдеры и табы
simpleTab ({
    rootTag: ".js-tab",
    nameTag: ".js-tab-control",
    controlGroupTag: ".js-tab-control-group",
    itemTag: ".js-tab-item"
});

// Slick
$('.js-slick').slick({
  slidesToShow: 3,
  variableWidth: true,
  infinite: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        slidesToShow: 3
      }
    },
    {
      breakpoint: 640,
      settings: {
        arrows: false,
        centerMode: true,
        centerMode: true,
        slidesToShow: 1
      }
    }
  ]
});

$('.js-slick-dop').on('init', function(){
  // console.log("slick init");
  initPhotoSwipe(".js-pswp-gallery");
});

$('.js-slick-dop').slick({
  slidesToShow: 3,
  variableWidth: true,
  infinite: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: false,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 640,
      settings: {
        arrows: false,
        centerMode: false,
        slidesToShow: 2
      }
    }
  ]
});


popupItemsCut({
    rootTag: "body",
    itemTag: ".js-dop-item",
    btnTag: ".js-dop-open"
});

(function(){
  let linkList = Array.from(document.querySelectorAll('.js-about'));
  let box = document.querySelector('.js-about-box');
  linkList.forEach((link)=>{
    link.addEventListener("click", () => {
        box.classList.add("is-popup-open");
      }
    );
  });
})();

// пересчитываемые цифры
mathAppear({
  rootTag: ".js-appear-root",
  itemTag: ".js-appear-num",
  handleTag: ".js-appear-btn",
  time: "500"
});


// setTimeout(()=>{
//   // инициализация галереи (картинки и видео)
//   // initPhotoSwipe(".js-pswp-gallery");
// }, 1000);

parallaxSimple({
  el: ".js-parallax-background",
  shiftMin: 0,
  shiftMax: 210,
  offset: 200
});